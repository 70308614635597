import * as React from "react"

import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = props => (
  <Layout props={props}>
    <Seo title={props.pageContext.intl.messages.errorPageTitle} />

    <section className="section section-error">
      <div className="section-error__content">
        <div className="section-error__text">
          <h1 className="section__title">
            <FormattedMessage id="errorPageTitle" />
          </h1>
          <p className="section__subtitle">
            <FormattedMessage id="errorPageDescription" />
          </p>
        </div>
        <div className="section-error__btn">
          <a
            href="/"
            className="btn btn--red"
            aria-label={props.pageContext.intl.messages.errorPageLink}
          >
            <span className="small">
              <FormattedMessage id="errorPageLink" />
            </span>
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
