import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  IntlContextConsumer,
  changeLocale,
  FormattedMessage,
} from "gatsby-plugin-intl"
import classNames from "classnames"

import logo from "../images/logo.svg"

const languageName = {
  en: "En",
  it: "It",
  ru: "Ru",
}

const Header = ({ siteTitle, intl }) => {
  const [state, setState] = useState({
    dropdownState: false,
    dropdownPhoneState: false,
    mobileMenuState: false,
  })

  const openMobileMenu = () => {
    document.body.classList.toggle("Overflow")
    document.documentElement.classList.toggle("Overflow")

    setState({
      ...state,
      mobileMenuState: !state.mobileMenuState,
    })
  }

  const openChangePhone = e => {
    setState({
      ...state,
      dropdownPhoneState: e,
    })
  }

  const openChangeLang = e => {
    setState({
      ...state,
      dropdownState: e,
    })
  }

  const changeLang = lang =>
    fetch(`/infodesk/language/${lang}`)
      .then(response => response.json())
      .then(data => {
        document.body.classList.remove("Overflow")
        document.documentElement.classList.remove("Overflow")
        changeLocale(data.language)
      })
      .catch(() => {
        document.body.classList.remove("Overflow")
        document.documentElement.classList.remove("Overflow")
        changeLocale(lang)
      })

  return (
    <header className="header">
      <div className="header__logo">
        <a href="/">
          <img src={logo} width="230" height="64" alt={siteTitle} />
        </a>
      </div>
      <div className="header__toggle">
        <button
          type="button"
          className={classNames(`header__toggle__btn`, {
            open: state.mobileMenuState,
          })}
          onClick={() => openMobileMenu()}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
      <div
        className={classNames(`header__nav`, {
          open: state.mobileMenuState,
        })}
      >
        <a href="mailto:info@goodtranssrl.com" className="header__nav__mail">
          info@goodtranssrl.com
        </a>
        <div className="header__nav__phones">
          <div
            className={classNames(`header__nav__phone__dropdown`, {
              open: state.dropdownPhoneState,
            })}
            role="presentation"
            onMouseEnter={() => openChangePhone(true)}
            onMouseLeave={() => openChangePhone(false)}
          >
            <a href="tel:+390418686711" className="header__nav__phone">
              +39 0418 686711
            </a>
            <a href="tel:+390418685502" className="header__nav__phone">
              +39 0418 685502
            </a>
            <a href="tel:+390421271834" className="header__nav__phone">
              +39 0421 271834
            </a>
          </div>
        </div>
        <div className="header__nav__locatgion">
          <span>Via dell'Artigianato, 35-37, 30023 </span>Concordia Sagittaria VE, Italy
        </div>
        <div className="header__nav__links">
          <div className="header__nav__lang">
            <div
              className={classNames(`header__nav__lang--dropdown`, {
                open: state.dropdownState,
              })}
              role="presentation"
              onMouseEnter={() => openChangeLang(true)}
              onMouseLeave={() => openChangeLang(false)}
            >
              <span
                className="header__nav__lang--current"
                title={languageName[intl.language]}
              >
                {languageName[intl.language]}
              </span>
              <ul
                className={classNames(`header__nav__lang-list`, {
                  open: state.dropdownState,
                })}
              >
                <IntlContextConsumer>
                  {({ languages, language: currentLocale }) =>
                    languages.map(
                      language =>
                        currentLocale !== language && (
                          <li
                            key={language}
                            className={classNames(`lang--li`, {
                              selected: currentLocale === language,
                            })}
                          >
                            <button
                              onClick={() => changeLang(language)}
                              className={classNames(
                                `header__nav__lang--button`,
                                `header__nav__lang--button__${language}`,
                                {
                                  selected: currentLocale === language,
                                }
                              )}
                              title={languageName[language]}
                            >
                              {languageName[language]}
                            </button>
                          </li>
                        )
                    )
                  }
                </IntlContextConsumer>
              </ul>
            </div>
          </div>
          <a href="/PersonalAccount/" className="header__nav__auth">
            <FormattedMessage id="headerCabinetUrlName" />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
