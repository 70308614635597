import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../styles/style.scss"

const Layout = ({ children, props: { pageContext }, auth }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [footerOverlay, setFooterOverlay] = useState(0)

  useEffect(() => {
    const footerOverlayEvents = () => {
      const footerOverlaySelector = document.querySelector(".footer__overlay")
      const t = footerOverlaySelector.getBoundingClientRect()
      const elemTop = t.top
      const elemBottom = t.top + 0.25 * t.height

      if (elemTop >= 0 && elemBottom <= window.innerHeight) {
        const from = t.top - window.innerHeight
        const to = t.top - window.innerHeight + t.height - 50
        const n = Math.min(
          1,
          Math.max(0, (document.body.scrollTop - from) / (to - from))
        )
        setFooterOverlay(n)
      }
    }

    ;["scroll", "resize"].forEach(e => {
      window.addEventListener(e, footerOverlayEvents)
    })

    return () =>
      ["scroll", "resize"].forEach(e => {
        window.removeEventListener(e, footerOverlayEvents)
      })
  }, [footerOverlay])

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "//code.jivosite.com/widget/rGqfKuBFu3"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        intl={pageContext.intl}
        auth={auth}
      />

      <main>{children}</main>

      <footer className="footer">
        <div
          className="footer__overlay"
          style={{ opacity: 0.8 * (1 - footerOverlay) }}
        ></div>
        <div className="footer__container">
          <div className="footer__left">
            <div className="footer__logo"></div>
            <a href="mailto:info@goodtranssrl.com" className="footer__email">
              info@goodtranssrl.com
            </a>
            <div className="footer__location">
              Via dell'Artigianato, 35-37, 30023 Concordia Sagittaria VE, Italy
            </div>
          </div>
          <div className="footer__right">
            <div className="footer__group">
              <div className="footer__phones">
                <a href="tel:+390418686711" className="footer__phone">
                  +39 0418 686711
                </a>
                <a href="tel:+390418685502" className="footer__phone">
                  +39 0418 685502
                </a>
                <a href="tel:+390421271834" className="header__nav__phone">
                  +39 0421 271834
                </a>
              </div>
              <ul className="footer__messagers list--clean">
                <li>
                  <a
                    href="https://t.me/Anna_Tytova"
                    className="footer__messagers__telegram"
                  >
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href="viber://chat?number=+74991130728"
                    className="footer__messagers__viber"
                  >
                    Viber
                  </a>
                </li>
                <li>
                  <a
                    href="whatsapp://send?phone=74991130728"
                    className="footer__messagers__whatsapp"
                  >
                    Whatsapp
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__copyright">
              © {new Date().getFullYear()}. goodtranssrl.com
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
